<div class="layout-wrapper d-lg-flex">
  <div class="side-menu flex-lg-column me-lg-1">
    <!-- LOGO -->
    <div class="navbar-brand-box">
      <a routerLink="/" class="logo logo-dark">
        <span class="logo-sm">
          <img src="assets/images/IOS.png" alt="" class="img-fluid" style="padding:10px">
        </span>
      </a>

      <button type="button" style="display:none" class="btn nav-btn" data-bs-toggle="modal" id="videocallModal" data-bs-target="#videocallModal"
                    (click)="openVideoModal(videoContent)">
                  <i class="ri-vidicon-line"></i>
                </button>
      <a routerLink="/" class="logo logo-light">
        <span class="logo-sm">
          <img src="assets/images/IOS.png" alt="" height="30">
        </span>
      </a>
      <b>{{ userGroups[dataService.currentUser.usergroup] }}</b>
    </div>
    <!-- end navbar-brand-box -->

    <!-- Start side-menu nav -->
    <div class="flex-lg-column my-auto">
      <ul class="nav nav-pills side-menu-nav justify-content-center" role="tablist">
        <li class="nav-item" ngbTooltip="Profile">
          <a class="nav-link" id="pills-user-tab" (click)="activetab=1" href="javascript: void(0);"
            [ngClass]="{'active': activetab === 1}">
            <i class="ri-user-2-line"></i>
          </a>
        </li>
        <li class="nav-item" ngbTooltip="Chats">
          <a class="nav-link active" id="pills-chat-tab" (click)="activetab=2" href="javascript: void(0);"
            [ngClass]="{'active': activetab === 2}">
            <i class="ri-message-3-line"></i>
          </a>
        </li>
        <li class="nav-item" ngbTooltip="Groups">
          <a class="nav-link pills-groups-tab" id="pills-groups-tab" (click)="activetab=3" href="javascript: void(0);"
            [ngClass]="{'active': activetab === 3}">
            <i class="ri-group-line"></i>
          </a>
        </li>
        <li class="nav-item" ngbTooltip="Contacts" placement="top">
          <a class="nav-link" id="pills-contacts-tab" (click)="activetab=4" href="javascript: void(0);"
            [ngClass]="{'active': activetab === 4}">
            <i class="ri-contacts-line"></i>
          </a>
        </li>
        <li class="nav-item" ngbTooltip="Settings">
          <a class="nav-link" id="pills-setting-tab" (click)="activetab=5" href="javascript: void(0);"
            [ngClass]="{'active': activetab === 5}">
            <i class="ri-settings-2-line"></i>
          </a>
        </li>


        <li *ngIf="dataService.currentUser.usergroup == 1" class="nav-item" ngbTooltip="Company settings">
          <a class="nav-link" id="pills-setting-tab" (click)="activetab=6" href="javascript: void(0);"
            [ngClass]="{'active': activetab === 6}">
            <i class="ri-admin-line"></i>
          </a>
        </li>


        <li class="nav-item dropdown profile-user-dropdown d-inline-block d-lg-none" ngbDropdown placement="top">
          <a class="nav-link dropdown-toggle" href="javascript: void(0);" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>             
            <div *ngIf="dataService.currentUser.firstname"> 
              <div class="avatar" [ngClass]="type" [ngStyle]="{ 'background-image': 'url(' + dataService.currentUser.avatarFullUrl + ')'}" *ngIf="dataService.currentUser.avatarFullUrl" ></div>
              <div *ngIf="!dataService.currentUser.avatarFullUrl" [ngClass]="type" class="avatar horizontal layout center center-justified" [ngStyle]="{ 'background-color': dataService.currentUser.bgColor }">{{ dataService.currentUser.firstname ? dataService.currentUser.firstname[0] : "no-name"}}</div>
            </div>    
          </a>
          <div class="dropdown-menu" ngbDropdownMenu>
            <a class="dropdown-item" href="javascript:void(0);">{{'chat.profiledropdown.profile' | translate}} <i
                class="ri-profile-line float-end text-muted"></i></a>
            <a class="dropdown-item" href="javascript:void(0);">{{'chat.profiledropdown.setting' | translate}} <i
                class="ri-settings-3-line float-end text-muted"></i></a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="javascript: void(0);" (click)="logout()">{{'chat.profiledropdown.logout' |
              translate}} <i class="ri-logout-circle-r-line float-end text-muted"></i></a>
          </div>
        </li>
      </ul>
    </div>
    <!-- end side-menu nav -->

    <div class="flex-lg-column d-none d-lg-block">
      <ul class="nav side-menu-nav justify-content-center">

        <li class="nav-item btn-group dropup profile-user-dropdown" ngbDropdown placement="top-left">
          <a class="nav-link dropdown-toggle" href="javascript:void(0);" role="button" ngbDropdownToggle
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="ri-global-line"></i>
          </a>
          <div class="dropdown-menu" ngbDropdownMenu>
            <a class="dropdown-item" *ngFor="let item of listLang" (click)="setLanguage(item.lang)"
              [ngClass]="{'active': lang === item.lang}" href="javascript: void(0);">
              <img src="{{item.flag}}" alt="user-image" class="me-1" height="12"> <span
                class="align-middle">{{item.text}}</span>
            </a>
          </div>
        </li>

        <li class="nav-item">
          <a class="nav-link" id="light-dark" href="javascript:void(0);" ngbTooltip="Light Mode">
            <i class="ri-sun-line theme-mode-icon layout-mode-dark" (click)="changeMode('dark')"></i>
            <i class="ri-sun-line theme-mode-icon layout-mode-light" (click)="changeMode('light')"></i>
          </a>
        </li>

        <li class="nav-item btn-group dropup profile-user-dropdown" ngbDropdown placement="top-left">
          <a class="nav-link dropdown-toggle" href="javascript:void(0);" role="button" ngbDropdownToggle
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            
            <div *ngIf="dataService.currentUser.firstname"> 
              <div class="avatar" [ngClass]="type" [ngStyle]="{ 'background-image': 'url(' + dataService.currentUser.avatarFullUrl + ')'}" *ngIf="dataService.currentUser.avatarFullUrl" ></div>
              <div *ngIf="!dataService.currentUser.avatarFullUrl" [ngClass]="type" class="avatar horizontal layout center center-justified" [ngStyle]="{ 'background-color': dataService.currentUser.bgColor }">{{ dataService.currentUser.firstname ? dataService.currentUser.firstname[0] : "no-name"}}</div>
            </div>    
           </a>
          <div class="dropdown-menu" ngbDropdownMenu>
            <a class="dropdown-item" href="javascript:void(0);">{{'chat.profiledropdown.profile' | translate}} <i
                class="ri-profile-line float-end text-muted"></i></a>
            <a class="dropdown-item" href="javascript:void(0);">{{'chat.profiledropdown.setting' | translate}} <i
                class="ri-settings-3-line float-end text-muted"></i></a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="javascript: void(0);" (click)="logout()">{{'chat.profiledropdown.logout' |
              translate}} <i class="ri-logout-circle-r-line float-end text-muted"></i></a>
          </div>
        </li>
      </ul>
    </div>
    <!-- Side menu user -->
  </div>
  <!-- start chat-leftsidebar -->
  <div class="chat-leftsidebar me-lg-1" [ngClass]="activetab === 6 ? 'w-100' : ''" >
    <div class="tab-content">
      <!-- Start Profile tab-pane -->
      <div id="pills-user" role="tabpanel" aria-labelledby="pills-user-tab" *ngIf="activetab === 1">
        <app-profile></app-profile>
      </div>
      <!-- End Profile tab-pane-->

      <!-- Start chats tab-pane -->
      <div id="pills-chat" role="tabpanel" aria-labelledby="pills-chat-tab" *ngIf="activetab === 2">
        <!-- Start chats content -->
        <div>

          <div class="px-4 pt-4">
            <h4 class="mb-4">{{'chat.tabs.chats.title' | translate}}</h4>
            <div class="search-box chat-search-box">
              <div class="input-group mb-3 rounded-3">
                <span class="input-group-text text-muted bg-light pe-1 ps-3" id="basic-addon1">
                  <i class="ri-search-line search-icon font-size-18"></i>
                </span>
                <input type="text" class="form-control bg-light"
                  placeholder="{{'chat.tabs.chats.search.placeholder' | translate}}"
                  aria-label="Search messages or users" aria-describedby="basic-addon1" [(ngModel)]="textSearch"
                  id="searchContact" autocomplete="off">
              </div>
            </div>
          </div> <!-- .p-4 -->

          <!-- Start user status -->
          <div class="px-4 pb-4" dir="ltr">
            <owl-carousel-o [options]="customOptions">
              <ng-template carouselSlide>
                <div class="item">
                  <a href="javascript:void(0);" class="user-status-box">
                    <div class="avatar-xs mx-auto d-block chat-user-img online">
                      <img src="assets/images/users/avatar-2.jpg" alt="user-img" class="img-fluid rounded-circle">
                      <span class="user-status"></span>
                    </div>

                    <h5 class="font-size-13 text-truncate mt-3 mb-1">{{'chat.tabs.chats.users.1' | translate}}</h5>
                  </a>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="item">
                  <a href="javascript:void(0);" class="user-status-box">
                    <div class="avatar-xs mx-auto d-block chat-user-img online">
                      <img src="assets/images/users/avatar-4.jpg" alt="user-img" class="img-fluid rounded-circle">
                      <span class="user-status"></span>
                    </div>

                    <h5 class="font-size-13 text-truncate mt-3 mb-1">{{'chat.tabs.chats.users.2' | translate}}</h5>
                  </a>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="item">
                  <a href="javascript:void(0);" class="user-status-box">
                    <div class="avatar-xs mx-auto d-block chat-user-img online">
                      <img src="assets/images/users/avatar-5.jpg" alt="user-img" class="img-fluid rounded-circle">
                      <span class="user-status"></span>
                    </div>

                    <h5 class="font-size-13 text-truncate mt-3 mb-1">{{'chat.tabs.chats.users.3' | translate}}</h5>
                  </a>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="item">
                  <a href="javascript:void(0);" class="user-status-box">
                    <div class="avatar-xs mx-auto d-block chat-user-img online">
                      <img src="assets/images/users/avatar-6.jpg" alt="user-img" class="img-fluid rounded-circle">
                      <span class="user-status"></span>
                    </div>

                    <h5 class="font-size-13 text-truncate mt-3 mb-1">{{'chat.tabs.chats.users.4' | translate}}</h5>
                  </a>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="item">
                  <a href="javascript:void(0);" class="user-status-box">
                    <div class="avatar-xs mx-auto d-block chat-user-img online">
                      <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                        T
                      </span>
                      <span class="user-status"></span>
                    </div>

                    <h5 class="font-size-13 text-truncate mt-3 mb-1">{{'chat.tabs.chats.users.5' | translate}}</h5>
                  </a>
                </div>
              </ng-template>

            </owl-carousel-o>

            <!-- end user status carousel -->
          </div>
          <!-- end user status -->

          <!-- Start chat-message-list -->
          <div class="px-2">
            <h5 class="mb-3 px-3 font-size-16">{{'chat.tabs.chats.recent.title' | translate}}</h5>
            <div class="row my-3">
              <div class="col-6">
                <button class="btn btn-default w-100" [ngClass]="{'btn-primary': chatTab == '0'}" (click)="changeChatTab(0)">Active</button>
              </div>
              <div class="col-6">
                <button class="btn btn-default w-100" [ngClass]="{'btn-primary': chatTab == '1'}" (click)="changeChatTab(1)">Archived</button>
              </div>
            </div>
            <perfect-scrollbar class="chat-message-list">
              <ul class="list-unstyled chat-list chat-user-list">
                <li *ngFor="let user of listGroups()" [ngClass]="{'new-request': user.operator_id == 0 && user.is_request}" (click)="showChat($event,user)">

                  <a href="javascript:void(0);">
                    <div class="d-flex">
                      <div class="chat-user-img online align-self-center me-3 ms-0">
                        <img *ngIf="user.avatarFullUrl" src="{{user.avatarFullUrl}}" class="rounded-circle avatar-xs"
                          alt="">
                        <div class="avatar-xs" *ngIf="!user.avatarFullUrl ">
                          <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                            {{( user.name ? user.name : "No name"  | translate).charAt(0)}}
                          </span>
                        </div>
                        <!--<span *ngIf="user.status" class="user-status"></span>-->
                      </div>

                      <div class="flex-grow-1 overflow-hidden">
                        <h5 class="text-truncate font-size-15 mb-1">{{ user.name ? user.name : "No name" }}</h5>
                        <p *ngIf="user.isTyping" class="chat-user-message text-truncate mb-0">typing<span
                            class="animate-typing">
                            <span class="dot ms-1"></span>
                            <span class="dot ms-1"></span>
                            <span class="dot ms-1"></span>
                          </span></p>
                        <p class="chat-user-message text-truncate mb-0">{{user.lastMessage}}</p>
                      </div>
                      <div class="font-size-11">{{ datePipe.transform(user.updatedAt,"h:mm a")  | translate}}</div>
                      <div class="unread-message" *ngIf="user.readCount">
                        <span class="badge badge-soft-danger rounded-pill">{{user.readCount}}</span>
                      </div>
                    </div>
                  </a>
                  
                </li>
              </ul>
            </perfect-scrollbar>

          </div>
          <!-- End chat-message-list -->
        </div>
        <!-- Start chats content -->
      </div>
      <!-- End chats tab-pane -->

      <!-- Start groups tab-pane -->
      <div id="pills-groups" role="tabpanel" aria-labelledby="pills-groups-tab" *ngIf="activetab === 3">
        <!-- Start Groups content -->
        <div>
          <div class="p-4">
            <div class="user-chat-nav float-end">
              <div ngbTooltip="Create group">
                <!-- Button trigger modal -->
                <button type="button" class="btn btn-link text-decoration-none text-muted font-size-18 py-0"
                  data-toggle="modal" data-target="#addgroup-exampleModal" (click)="openGroupModal(content)">
                  <i class="ri-group-line me-1"></i>
                </button>
              </div>
            </div>
            <h4 class="mb-4">{{'chat.tabs.groups.title' | translate}}</h4>

            <!-- Start add group Modal -->
            <ng-template #content let-modal>
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title font-size-16" id="addgroup-exampleModalLabel">
                    {{'chat.tabs.groups.modal.title' | translate}}</h5>
                  <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
                    (click)="modal.dismiss('Cross click')">
                  </button>
                </div>
                <div class="modal-body p-4">
                  <form>
                    <div class="mb-4">
                      <label class="form-label" for="addgroupname-input">{{'chat.tabs.groups.modal.form.name.label' |
                        translate}}</label>
                      <input type="text" class="form-control" id="addgroupname-input"
                        placeholder="{{'chat.tabs.groups.modal.form.name.placeholder' | translate}}">
                    </div>
                    <div class="mb-4">
                      <label class="form-label">{{'chat.tabs.groups.modal.form.members.label' | translate}}</label>
                      <div class="mb-3">
                        <button class="btn btn-light btn-sm" type="button" data-toggle="collapse"
                          (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
                          aria-controls="collapseExample">
                          {{'chat.tabs.groups.modal.form.members.button.text' | translate}}
                        </button>
                      </div>

                      <div class="collapse" id="groupmembercollapse" #collapse="ngbCollapse"
                        [(ngbCollapse)]="isCollapsed">
                        <div class="card border">
                          <div class="card-header">
                            <h5 class="font-size-15 mb-0">{{'chat.tabs.contacts.title' | translate}}</h5>
                          </div>
                          <div class="card-body p-2">
                            <perfect-scrollbar data-simplebar style="height: 150px;">
                              <div>
                                <div class="p-3 font-weight-bold text-primary">
                                  {{'chat.tabs.contacts.list.A.text' | translate}}
                                </div>

                                <ul class="list-unstyled contact-list">
                                  <li>
                                    <div class="form-check">
                                      <input type="checkbox" class="form-check-input" id="memberCheck1" checked="">
                                      <label class="form-check-label"
                                        for="memberCheck1">{{'chat.tabs.contacts.list.A.name' | translate}}</label>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="form-check">
                                      <input type="checkbox" class="form-check-input" id="memberCheck2">
                                      <label class="form-check-label"
                                        for="memberCheck2">{{'chat.tabs.contacts.list.A.name2' | translate}}</label>
                                    </div>
                                  </li>
                                </ul>
                              </div>

                              <div>
                                <div class="p-3 font-weight-bold text-primary">
                                  {{'chat.tabs.contacts.list.C.text' | translate}}
                                </div>

                                <ul class="list-unstyled contact-list">
                                  <li>
                                    <div class="form-check">
                                      <input type="checkbox" class="form-check-input" id="memberCheck3">
                                      <label class="form-check-label" for="memberCheck3">
                                        {{'chat.tabs.contacts.list.C.name' | translate}}</label>
                                    </div>
                                  </li>
                                </ul>
                              </div>

                              <div>
                                <div class="p-3 font-weight-bold text-primary">
                                  {{'chat.tabs.contacts.list.D.text' | translate}}
                                </div>

                                <ul class="list-unstyled contact-list">
                                  <li>
                                    <div class="form-check">
                                      <input type="checkbox" class="form-check-input" id="memberCheck4">
                                      <label class="form-check-label" for="memberCheck4">
                                        {{'chat.tabs.contacts.list.D.name' | translate}}</label>
                                    </div>
                                  </li>
                                </ul>
                              </div>

                              <div>
                                <div class="p-3 font-weight-bold text-primary">
                                  {{'chat.tabs.contacts.list.I.text' | translate}}
                                </div>

                                <ul class="list-unstyled contact-list">
                                  <li>
                                    <div class="form-check">
                                      <input type="checkbox" class="form-check-input" id="memberCheck5">
                                      <label class="form-check-label" for="memberCheck5">
                                        {{'chat.tabs.contacts.list.I.name' | translate}}</label>
                                    </div>
                                  </li>

                                </ul>
                              </div>

                              <div>
                                <div class="p-3 font-weight-bold text-primary">
                                  {{'chat.tabs.contacts.list.J.text' | translate}}
                                </div>

                                <ul class="list-unstyled contact-list">
                                  <li>
                                    <div class="form-check">
                                      <input type="checkbox" class="form-check-input" id="memberCheck6">
                                      <label class="form-check-label"
                                        for="memberCheck6">{{'chat.tabs.contacts.list.J.name' | translate}}</label>
                                    </div>
                                  </li>

                                  <li>
                                    <div class="form-check">
                                      <input type="checkbox" class="form-check-input" id="memberCheck7">
                                      <label class="form-check-label"
                                        for="memberCheck7">{{'chat.tabs.contacts.list.J.name2' | translate}}</label>
                                    </div>
                                  </li>

                                  <li>
                                    <div class="form-check">
                                      <input type="checkbox" class="form-check-input" id="memberCheck8">
                                      <label class="form-check-label"
                                        for="memberCheck8">{{'chat.tabs.contacts.list.J.name3' | translate}}</label>
                                    </div>
                                  </li>

                                </ul>
                              </div>

                              <div>
                                <div class="p-3 font-weight-bold text-primary">
                                  {{'chat.tabs.contacts.list.M.text' | translate}}
                                </div>

                                <ul class="list-unstyled contact-list">
                                  <li>
                                    <div class="form-check">
                                      <input type="checkbox" class="form-check-input" id="memberCheck9">
                                      <label class="form-check-label"
                                        for="memberCheck9">{{'chat.tabs.contacts.list.M.name' | translate}}</label>
                                    </div>
                                  </li>

                                  <li>
                                    <div class="form-check">
                                      <input type="checkbox" class="form-check-input" id="memberCheck10">
                                      <label class="form-check-label"
                                        for="memberCheck10">{{'chat.tabs.contacts.list.M.name2' | translate}}</label>
                                    </div>
                                  </li>

                                </ul>
                              </div>

                              <div>
                                <div class="p-3 font-weight-bold text-primary">
                                  {{'chat.tabs.contacts.list.P.text' | translate}}
                                </div>

                                <ul class="list-unstyled contact-list">
                                  <li>
                                    <div class="form-check">
                                      <input type="checkbox" class="form-check-input" id="memberCheck11">
                                      <label class="form-check-label" for="memberCheck11">
                                        {{'chat.tabs.contacts.list.P.name' | translate}}</label>
                                    </div>
                                  </li>

                                </ul>
                              </div>

                              <div>
                                <div class="p-3 font-weight-bold text-primary">
                                  {{'chat.tabs.contacts.list.R.text' | translate}}
                                </div>

                                <ul class="list-unstyled contact-list">
                                  <li>
                                    <div class="form-check">
                                      <input type="checkbox" class="form-check-input" id="memberCheck12">
                                      <label class="form-check-label" for="memberCheck12">
                                        {{'chat.tabs.contacts.list.R.name' | translate}}</label>
                                    </div>
                                  </li>

                                </ul>
                              </div>

                              <div>
                                <div class="p-3 font-weight-bold text-primary">
                                  {{'chat.tabs.contacts.list.S.text' | translate}}
                                </div>

                                <ul class="list-unstyled contact-list">
                                  <li>
                                    <div class="form-check">
                                      <input type="checkbox" class="form-check-input" id="memberCheck13">
                                      <label class="form-check-label"
                                        for="memberCheck13">{{'chat.tabs.contacts.list.S.name' | translate}}</label>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </perfect-scrollbar>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="form-label"
                        for="addgroupdescription-input">{{'chat.tabs.groups.modal.form.description.label' |
                        translate}}</label>
                      <textarea class="form-control" id="addgroupdescription-input" rows="3"
                        placeholder="{{'chat.tabs.groups.modal.form.description.placeholder' | translate}}"></textarea>
                    </div>
                  </form>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-link" data-dismiss="modal"
                    (click)="modal.dismiss('Cross click')">{{'chat.tabs.groups.modal.form.button.close' |
                    translate}}</button>
                  <button type="button" class="btn btn-primary">{{'chat.tabs.groups.modal.form.button.create' |
                    translate}}</button>
                </div>
              </div>
            </ng-template>
            <!-- End add group Modal -->

            <div class="search-box chat-search-box">
              <div class="input-group rounded-3">
                <button class="input-group-text text-muted bg-light pe-1 ps-3" type="button">
                  <i class="ri-search-line search-icon font-size-18"></i>
                </button>
                <input type="text" class="form-control bg-light"
                  placeholder="{{'chat.tabs.groups.search.placeholder' | translate}}" (keyup)="GroupSearch()"
                  id="searchGroup" autocomplete="off">
              </div>
            </div>
            <!-- end search-box -->
          </div>

          <!-- Start chat-group-list -->
          <perfect-scrollbar class="p-4 chat-message-list chat-group-list" data-simplebar>
            <ul class="list-unstyled chat-list group-list">
              <li *ngFor="let item of listWorkGroups()" (click)="showChat($event,item)">
                <a href="javascript:void(0);">
                  <div class="d-flex align-items-center">
                    <div class="chat-user-img me-3 ms-0">
                      <div class="avatar-xs">
                        <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                          {{(item.name | translate).charAt(0)}}
                        </span>
                      </div>
                    </div>
                    <div class="flex-grow-1 overflow-hidden">
                      <h5 class="text-truncate font-size-14 mb-0">#{{item.name | translate}}
                        <span *ngIf="item.readCount" class="badge badge-soft-danger rounded-pill float-end">+{{item.readCount}}</span>
                      </h5>
                    </div>
                    <div class="font-size-11">{{ datePipe.transform(item.updatedAt,"h:mm a")  | translate}}</div>
                    
                  </div>
                </a>
              </li>
            </ul>
          </perfect-scrollbar>
          <!-- End chat-group-list -->
        </div>
        <!-- End Groups content -->
      </div>
      <!-- End groups tab-pane -->

      <!-- Start contacts tab-pane -->
      <div id="pills-contacts" role="tabpanel" aria-labelledby="pills-contacts-tab" *ngIf="activetab === 4">
        <app-contacts></app-contacts>
      </div>
      <!-- End contacts tab-pane -->

      <!-- Start settings tab-pane -->
      <div id="pills-setting" role="tabpanel" aria-labelledby="pills-setting-tab" *ngIf="activetab === 5">
        <app-settings></app-settings>
      </div>


      <!-- Start Profile tab-pane -->
      <div id="company-user" role="tabpanel" aria-labelledby="pills-company-tab" *ngIf="activetab === 6">
        <app-company></app-company>
      </div>
      <!-- End Profile tab-pane-->
      <!-- End settings tab-pane -->
    </div>

  </div>

  <div class="chat-welcome-section" [ngClass]="activetab === 6 ? 'display-none' : ''" >
    <div class="row w-100 justify-content-center">
      <div class="col-xxl-5 col-md-7">
        <div class="p-4 text-center">
          <div class="avatar-xl mx-auto mb-4">
            <div class="avatar-title bg-secondary rounded-circle">
              <svg data-v-5e8ea5c2="" xmlns="http://www.w3.org/2000/svg" width="70px" height="65px" viewBox="0 0 24 24"
                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-message-square">
                <path data-v-5e8ea5c2="" d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z">
                </path>
              </svg>
            </div>
          </div>
          <h4 class="d-inline px-3 py-2 rounded-pill bg-secondary text-white fs-4"> Start Conversation </h4>
        </div>
      </div>
    </div>
  </div>

  

  <!-- Start User chat -->
  <div class="user-chat w-100 d-none" [ngClass]="activetab === 6 ? 'display-none' : ''" id="chat-room">
    <div class="d-lg-flex">
      <!-- start chat conversation section -->
      <div class="w-100 position-relative">
        <div class="p-3 p-lg-4 border-bottom">
          <div class="row align-items-center">
            <div class="col-sm-4 col-8">
              <div class="d-flex align-items-center">
                <div class="d-block d-lg-none me-2 ms-0">
                  <a href="javascript: void(0);" class="user-chat-remove text-muted font-size-16 p-2"
                    (click)="closeUserChat()"><i class="ri-arrow-left-s-line"></i></a>
                </div>
                <div class="me-3 ms-0">
                  <img *ngIf="userProfile" src="{{userProfile}}" class="rounded-circle avatar-xs" alt="">
                  <div class="avatar-xs" *ngIf="!userProfile">
                    <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                      {{(userName | translate).charAt(0)}}
                    </span>
                  </div>
                </div>
                <div class="flex-grow-1 overflow-hidden">
                  <h5 class="font-size-16 mb-0 text-truncate">
                    <a href="javascript:void(0);" class="text-reset user-profile-show username"
                      (click)="showGroupInfo()">{{userName | translate}}</a>
                      
                  </h5>
                  <h5 class="font-size-16 mb-0 text-truncate danger" *ngIf="dataService.group.status == 1">[ARCHIVED]</h5>
                </div>
              </div>
            </div>
            <div class="col-sm-8 col-4">
              <ul class="list-inline user-chat-nav text-end mb-0">
                <li class="list-inline-item">
                  <div class="dropdown" ngbDropdown>
                    <button class="btn nav-btn dropdown-toggle" ngbDropdownToggle type="button" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      <i class="ri-search-line"></i>
                    </button>
                    <div class="dropdown-menu p-0 dropdown-menu-right dropdown-menu-md" ngbDropdownMenu>
                      <div class="search-box p-2">
                        <input type="text" class="form-control bg-light border-0"
                          placeholder="{{'chat.chatpanelheader.search.placeholder' | translate}}"
                          (keyup)="MessageSearch()" id="searchMessage">
                      </div>
                    </div>
                  </div>
                </li>
                <li  *ngIf="dataService.group.status != 1" class="list-inline-item d-none d-lg-inline-block me-2 ms-0">
                  <button type="button" class="btn nav-btn" data-bs-toggle="modal" data-bs-target="#videocallModal"
                    (click)="initVideoCall()">
                    <i class="ri-vidicon-line"></i>
                  </button>
                </li>
                <ng-template #videoContent let-modal>
                  <div class="modal-body">
                    <div class="text-center p-4">
                      <div class="avatar-lg mx-auto mb-4">
                        <img src="{{userProfile}}" alt="" class="img-thumbnail rounded-circle">
                      </div>
                      <h5 class="text-truncate">{{(userName | translate)}}</h5>
                      <p class="text-muted mb-0">Start Video Call</p>
                      <div class="remotevideocontainer">
                        <video class="{{remoteVideo}}" id="remoteVideo" preload="none" ></video>
                      </div>
                    <video class="{{localVideo}}" preload="none" id="localVideo" muted="muted"></video>
                      <div class="mt-5" style="position: absolute;z-index: 9;bottom: 0;width: 100%;">
                        <ul class="list-inline mb-1">
                          <li class="list-inline-item px-2 me-2 ms-0">
                            <button type="button" class="btn btn-danger avatar-sm rounded-circle"
                              data-bs-dismiss="modal" (click)="modal.close('Close click')">
                              <span class="avatar-title bg-transparent font-size-20">
                                <i class="ri-close-fill"></i>
                              </span>
                            </button>
                          </li>
                          <li class="list-inline-item px-2">
                            <button id="accept" (click)="answer()" type="button" class="btn btn-success avatar-sm rounded-circle">
                              <span class="avatar-title bg-transparent font-size-20">
                                <i class="ri-vidicon-fill"></i>
                              </span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <li  *ngIf="dataService.group.status != 1" class="list-inline-item d-none d-lg-inline-block">
                  <button type="button" class="btn nav-btn user-profile-show" (click)="showGroupInfo(userInfo)">
                    <i class="ri-user-2-line"></i>
                  </button>
                </li>

                <li  *ngIf="dataService.group.status != 1" class="list-inline-item d-none d-lg-inline-block me-2 ms-0">
                  <button type="button" class="btn nav-btn" data-bs-toggle="modal" data-bs-target="#audiocallModal"
                    (click)="openInvite()">
                    <i class="ri-user-add-line"></i>
                  </button>
                </li>
                <li  class="list-inline-item">
                  <div class="dropdown" ngbDropdown>
                    <button class="btn nav-btn dropdown-toggle" ngbDropdownToggle type="button" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      <i class="ri-more-fill"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                      
                      <a  *ngIf="dataService.group.status != 1" class="dropdown-item" (click)="changeGroupStatus(dataService.group.id, 1)" href="javascript:void(0);">{{'chat.chatpanelheader.dropdown.archive' |
                        translate}} <i class="ri-archive-line float-end text-muted"></i></a>
                        <a  *ngIf="dataService.group.status == 1" class="dropdown-item" (click)="changeGroupStatus(dataService.group.id, 0)" href="javascript:void(0);">Activate <i class="ri-inbox-unarchive-line"></i></a>
                     
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- end chat user head -->
        <div id="users-chat" class="position-relative">
          <!-- start chat conversation -->
          <ngx-simplebar class="chat-conversation p-3 p-lg-4" #scrollRef>
            <ul class="list-unstyled mb-0" id="users-conversation">
              <li class="chats" *ngFor="let data of message;let i =index;"
                [ngClass]="{'right': !data.isYourMessage}">
                <div class="conversation-list" *ngIf="!data.isToday">
                  <div (click)="showUserInfo(data.from)" style="cursor:pointer" class="chat-avatar" *ngIf="data.align !== 'right'">
                    <img src="{{data.from.avatarFullUrl}}" alt="" *ngIf="data.from.avatarFullUrl">
                    <div class="avatar-xs" *ngIf="!data.from.avatarFullUrl">
                      <span class="avatar-title rounded-circle bg-soft-primary text-primary" style="background-color:{{data.from.bgColor}}">
                        {{(data.from.fullName ? data.from.fullName : data.from.lastname + " " + data.from.firstname | translate).charAt(0)}}
                      </span>
                    </div>
                  </div>
                  <div class="chat-avatar" *ngIf="!data.isYourMessage">
                  </div>

                  <div class="user-chat-content">
                    <div class="ctext-wrap">
                      <div class="ctext-wrap-content">
                        <div class="replymessage-block mb-0 d-flex align-items-start" *ngIf="data.replayName">
                          <div class="flex-grow-1">
                            <h5 class="conversation-name">{{data.replayName}}</h5>
                            <p class="mb-0">{{data.replaymsg | translate}}</p>
                          </div>
                          <div class="flex-shrink-0">
                            <button type="button" class="btn btn-sm btn-link mt-n2 me-n3 font-size-18">
                            </button>
                          </div>
                        </div>
                        <p class="mb-0 messageText">

                   
                          {{data.text | translate}}
                          <!-- <span class="animate-typing" *ngIf="data.istyping">
                            <span class="dot ms-1"></span>
                            <span class="dot ms-1"></span>
                            <span class="dot ms-1"></span>
                          </span> -->
                        </p>
                        <ul class="list-inline message-img mb-0" *ngIf="data.image">
                          <li class="list-inline-item message-img-list">
                            <div>
                              <a class="popup-img d-inline-block m-1 p-0" href="javascript:void(0);" title="Project 1">
                                <img :src="{{data.image}}" alt="" class="rounded border" (click)="openImage(image,image)">
                              </a>
                            </div>
                            <div class="message-img-link">
                              <ul class="list-inline mb-0">
                                <li class="list-inline-item">
                                  <a href="javascript:void(0);">
                                    <i class="ri-download-2-line"></i>
                                  </a>
                                </li>
                                <li class="list-inline-item dropdown" ngbDropdown>
                                  <a class="dropdown-toggle" href="javascript:void(0);" role="button" ngbDropdownToggle
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="ri-more-fill"></i>
                                  </a>
                                  <div class="dropdown-menu" ngbDropdownMenu>
                                    <a class="dropdown-item" href="javascript:void(0);">{{'chat.messages.dropdown.copy'
                                      | translate}} <i class="ri-file-copy-line float-end text-muted"></i></a>
                                    <a class="dropdown-item" href="javascript:void(0);">{{'chat.messages.dropdown.save'
                                      | translate}} <i class="ri-save-line float-end text-muted"></i></a>
                                    <a class="dropdown-item"
                                      href="javascript:void(0);">{{'chat.messages.dropdown.forward' | translate}} <i
                                        class="ri-chat-forward-line float-end text-muted"></i></a>
                                    <a class="dropdown-item" href="javascript:void(0);"
                                      (click)="deleteMessage($event)">{{'chat.messages.dropdown.delete' | translate}}<i
                                        class="ri-delete-bin-line float-end text-muted"></i></a>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                        <div class="card p-2 mb-2" *ngIf="data.isfile">
                          <div class="d-flex align-items-center">
                            <div class="avatar-sm me-3 ms-0">
                              <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                                <i class="ri-file-text-fill"></i>
                              </div>
                            </div>
                            <div class="d-flex-body">
                              <div class="text-start">
                                <h5 class="font-size-14 mb-1">{{data.fileContent}}</h5>
                                <p class="text-muted font-size-13 mb-0">{{data.fileSize}}</p>
                              </div>
                            </div>

                            <div class="ms-4">
                              <ul class="list-inline mb-0 font-size-20">
                                <li class="list-inline-item">
                                  <a href="javascript:void(0);" class="text-muted">
                                    <i class="ri-download-2-line"></i>
                                  </a>
                                </li>
                                <li class="list-inline-item dropdown" ngbDropdown>
                                  <a class="dropdown-toggle text-muted" href="javascript:void(0);" ngbDropdownToggle
                                    role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="ri-more-fill"></i>
                                  </a>
                                  <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                                    <a class="dropdown-item"
                                      href="javascript:void(0);">{{'chat.messages.filedropdown.share' | translate}}
                                      <i class="ri-share-line float-end text-muted"></i></a>
                                    <a class="dropdown-item" href="javascript:void(0);"
                                      (click)="deleteMessage($event)">{{'chat.messages.filedropdown.delete' |
                                      translate}}
                                      <i class="ri-delete-bin-line float-end text-muted"></i></a>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <p class="chat-time mb-0" *ngIf="!data.istyping">
                          <i class="ri-time-line align-middle"></i> 
                          <span class="align-middle">{{data.createdAt | translate}}</span>
                        </p>
                      </div>

                      <div class="dropdown align-self-start" ngbDropdown *ngIf="data.message">
                        <a class="dropdown-toggle" href="javascript:void(0);" role="button" ngbDropdownToggle
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="ri-more-2-fill"></i>
                        </a>
                        <div class="dropdown-menu" ngbDropdownMenu>
                          <a class="dropdown-item" href="javascript:void(0);"
                            (click)="replyMessage($event)">{{'chat.messages.dropdown.reply' | translate}} <i
                              class="ri-reply-line float-end text-muted"></i></a>
                          <a class="dropdown-item" href="javascript:void(0);"
                            (click)="copyMessage($event)">{{'chat.messages.dropdown.copy' | translate}} <i
                              class="ri-file-copy-line float-end text-muted"></i></a>
                          <a class="dropdown-item" href="javascript:void(0);">{{'chat.messages.dropdown.save' |
                            translate}} <i class="ri-save-line float-end text-muted"></i></a>
                          <a class="dropdown-item" href="javascript:void(0);"
                            (click)="centerModal(centerDataModal)">{{'chat.messages.dropdown.forward' | translate}} <i
                              class="ri-chat-forward-line float-end text-muted"></i></a>
                          <a class="dropdown-item" href="javascript:void(0);"
                            (click)="deleteMessage($event)">{{'chat.messages.dropdown.delete' | translate}}<i
                              class="ri-delete-bin-line float-end text-muted"></i></a>
                        </div>
                      </div>
                    </div>
                    <div class="ctext-wrap" *ngIf="data.message2">
                      <div class="ctext-wrap-content">
                        <p class="mb-0">
                          {{data.message2 | translate}}
                        </p>
                        <p class="chat-time mb-0"><i class="ri-time-line align-middle"></i> <span
                            class="align-middle">{{data.createdAt | translate}}</span></p>
                      </div>
                      <div class="dropdown align-self-start" ngbDropdown>
                        <a class="dropdown-toggle" href="javascript:void(0);" role="button" ngbDropdownToggle
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="ri-more-2-fill"></i>
                        </a>
                        <div class="dropdown-menu" ngbDropdownMenu>
                          <a class="dropdown-item" href="javascript:void(0);"
                            (click)="replyMessage($event)">{{'chat.messages.dropdown.reply' | translate}} <i
                              class="ri-reply-line float-end text-muted"></i></a>
                          <a class="dropdown-item" href="javascript:void(0);"
                            (click)="copyMessage($event)">{{'chat.messages.dropdown.copy' | translate}} <i
                              class="ri-file-copy-line float-end text-muted"></i></a>
                          <a class="dropdown-item" href="javascript:void(0);">{{'chat.messages.dropdown.save' |
                            translate}} <i class="ri-save-line float-end text-muted"></i></a>
                          <a class="dropdown-item" href="javascript:void(0);"
                            (click)="centerModal(centerDataModal)">{{'chat.messages.dropdown.forward' | translate}} <i
                              class="ri-chat-forward-line float-end text-muted"></i></a>
                          <a class="dropdown-item" href="javascript:void(0);"
                            (click)="deleteMessage($event)">{{'chat.messages.dropdown.delete' | translate}}<i
                              class="ri-delete-bin-line float-end text-muted"></i></a>
                        </div>
                      </div>
                    </div>
                    <div class="conversation-name"><span *ngIf="!data.isYourMessage">{{ data.from.fullName ? data.from.fullName : data.from.lastname + " " + data.from.firstname |
                        translate}}</span><span *ngIf="data.isYourMessage">{{ data.from.fullName ? data.from.fullName : data.from.lastname + " " + data.from.firstname | translate}}</span></div>
                  </div>
                </div>

                <div class="chat-day-title" *ngIf="data.isToday">
                  <span class="title">Today</span>
                </div>
              </li>
            </ul>
          </ngx-simplebar>
          <div class="alert alert-warning alert-dismissible copyclipboard-alert px-4 fade" id="copyClipBoard"
            role="alert">
            Message copied
          </div>
        </div>
        <!-- end chat conversation end -->

        <!-- start chat input section -->
        <div class="p-3 p-lg-4 border-top mb-0 send-msg">
          <form  [formGroup]="formData" (ngSubmit)="messageSave()" id="chatinput-form" enctype="multipart/form-data">
            <div class="row no-gutters">
              <div class="col">
                <div>
                  <input  *ngIf="dataService.group.status != 1" type="text" class="form-control form-control-lg bg-light border-light"
                    placeholder="Enter Message..." formControlName="message" [(ngModel)]="emoji" (blur)="onBlur()"
                    (focus)="onFocus()">
                    <input  *ngIf="dataService.group.status == 1" type="text" class="form-control form-control-lg bg-light border-light"
                    placeholder="This chat is archived" disabled="disabled"  (blur)="onBlur()"
                    (focus)="onFocus()">
                </div>
              </div>
              <div class="col-auto"  *ngIf="dataService.group.status != 1">
                <div class="chat-input-links ms-md-2">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <button type="button" class="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect"
                        data-toggle="tooltip" data-placement="top" ngbTooltip="Emoji" (click)="toggleEmojiPicker()"
                        id="emoji-btn">
                        <i class="ri-emotion-happy-line"></i>
                      </button>
                      <emoji-mart class="emoji-mart" *ngIf="showEmojiPicker" (emojiSelect)="addEmoji($event)"
                        title="Pick your emoji…"></emoji-mart>
                    </li>
                    <li class="list-inline-item">
                      <label id="files" class="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect">
                        <i class="ri-attachment-line"></i>
                        <input name="fileInput" size="60" type="file" class="form-control-file d-none"
                          (change)="fileChange($event)">
                      </label>
                    </li>
                    <li class="list-inline-item">
                      <button type="submit"
                        class="btn btn-primary font-size-16 btn-lg chat-send waves-effect waves-light">
                        <i class="ri-send-plane-2-fill"></i>
                      </button>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
          </form>
        </div>
        <!-- end chat input section -->

        <div _ngcontent-hes-c189="" class="replyCard">
          <div _ngcontent-hes-c189="" class="card mb-0">
            <div _ngcontent-hes-c189="" class="card-body py-3">
              <div _ngcontent-hes-c189="" class="replymessage-block mb-0 d-flex align-items-start">
                <div _ngcontent-hes-c189="" class="flex-grow-1">
                  <h5 _ngcontent-hes-c189="" class="conversation-name">You</h5>
                  <p _ngcontent-hes-c189="" class="mb-0">Wow that's great</p>
                </div>
                <div _ngcontent-hes-c189="" class="flex-shrink-0">
                  <button _ngcontent-hes-c189="" type="button" id="close_toggle"
                    class="btn btn-sm btn-link mt-n2 me-n3 fs-18 shadow-none" (click)="closeReplay()">
                    <i _ngcontent-xeu-c127="" class="ri-close-line float-end fs-5"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- end chat conversation section -->

      <!-- <app-profile-detail></app-profile-detail> -->
    </div>
  </div>
</div>

<!-- Chat Info Sidebar -->
<ng-template #userInfo let-offcanvas>
  <div class="offcanvas-header">
    <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
  </div>

  <div class="offcanvas-body profile-offcanvas p-0">
    <div class="text-center p-4 border-bottom">
      <div class="mb-4">
        <img src="{{userProfile}}" class="rounded-circle avatar-lg img-thumbnail" alt="">
      </div>
      <h5 class="font-size-16 mb-1 text-truncate">{{userName | translate}}</h5>
      <p class="text-muted text-truncate mb-1"><i class="ri-record-circle-fill font-size-10 me-1"
          [ngClass]=" {'text-success': userStatus === 'online','text-warning': userStatus === 'away'}"></i>
        {{userStatus | translate}}</p>
    </div>
    <!-- End profile user -->

    <!-- Start user-profile-desc -->
    <perfect-scrollbar class="p-4 user-profile-desc">
      <div class="text-muted">
        <p class="mb-4"> {{'chat.rightpanel.message' | translate}}</p>
      </div>

      <div id="profile-user-accordion" class="custom-accordion">
        <ngb-accordion #acc="ngbAccordion" activeIds="about" [closeOthers]="true">
          <ngb-panel cardClass="card shadow-none border mb-2" id="about">
            <ng-template ngbPanelTitle>
              <h5 class="font-size-14 m-0">
                <i class="ri-user-2-line me-2 align-middle d-inline-block"></i>
                {{'chat.rightpanel.about.text' | translate}}
              </h5>
            </ng-template>
            <ng-template ngbPanelContent>
              <div>
                <p class="text-muted mb-1">{{'chat.rightpanel.about.name.text' | translate}}</p>
                <h5 class="font-size-14">{{userName | translate}}</h5>
              </div>

              <div class="mt-4">
                <p class="text-muted mb-1">{{'chat.rightpanel.about.email.text' | translate}}</p>
                <h5 class="font-size-14">{{'chat.rightpanel.about.email.value' | translate}}</h5>
              </div>

              <div class="mt-4">
                <p class="text-muted mb-1">{{'chat.rightpanel.about.time.text' | translate}}</p>
                <h5 class="font-size-14">{{'chat.rightpanel.about.time.value' | translate}}</h5>
              </div>

              <div class="mt-4">
                <p class="text-muted mb-1">{{'chat.rightpanel.about.location.text' | translate}}</p>
                <h5 class="font-size-14 mb-0">{{'chat.rightpanel.about.location.value' | translate}}</h5>
              </div>
            </ng-template>
          </ngb-panel>
          <ngb-panel cardClass="card shadow-none border mb-2" id="file">
            <ng-template ngbPanelTitle>
              <h5 class="font-size-14 m-0">
                <i class="ri-attachment-line me-2 align-middle d-inline-block"></i>{{'chat.rightpanel.attachedfile.text'
                | translate}}
              </h5>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="card p-2 border mb-2">
                <div class="d-flex align-items-center">
                  <div class="avatar-sm me-3 ms-0">
                    <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                      <i class="ri-file-text-fill"></i>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <div class="text-start">
                      <h5 class="font-size-14 mb-1">{{'chat.rightpanel.attachedfile.file1.name' | translate}}</h5>
                      <p class="text-muted font-size-13 mb-0">{{'chat.rightpanel.attachedfile.file1.size' | translate}}
                      </p>
                    </div>
                  </div>

                  <div class="ms-4 me-0">
                    <ul class="list-inline mb-0 font-size-18">
                      <li class="list-inline-item">
                        <a href="javascript: void(0);" class="text-muted px-1">
                          <i class="ri-download-2-line"></i>
                        </a>
                      </li>
                      <li class="list-inline-item dropdown" ngbDropdown>
                        <a class="dropdown-toggle text-muted px-1" ngbDropdownToggle href="javascript:void(0);"
                          role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="ri-more-fill"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                          <a class="dropdown-item"
                            href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.action' |
                            translate}}</a>
                          <a class="dropdown-item"
                            href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.another' |
                            translate}}</a>
                          <div class="dropdown-divider"></div>
                          <a class="dropdown-item"
                            href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.delete' |
                            translate}}</a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- end card -->

              <div class="card p-2 border mb-2">
                <div class="d-flex align-items-center">
                  <div class="avatar-sm me-3 ms-0">
                    <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                      <i class="ri-image-fill"></i>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <div class="text-start">
                      <h5 class="font-size-14 mb-1">{{'chat.rightpanel.attachedfile.file2.name' | translate}}</h5>
                      <p class="text-muted font-size-13 mb-0">{{'chat.rightpanel.attachedfile.file2.name' | translate}}
                      </p>
                    </div>
                  </div>

                  <div class="ms-4 me-0">
                    <ul class="list-inline mb-0 font-size-18">
                      <li class="list-inline-item">
                        <a href="javascript: void(0);" class="text-muted px-1">
                          <i class="ri-download-2-line"></i>
                        </a>
                      </li>
                      <li class="list-inline-item dropdown" ngbDropdown>
                        <a class="dropdown-toggle text-muted px-1" ngbDropdownToggle href="javascript: void(0);"
                          role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="ri-more-fill"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                          <a class="dropdown-item"
                            href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.action' |
                            translate}}</a>
                          <a class="dropdown-item"
                            href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.another' |
                            translate}}</a>
                          <div class="dropdown-divider"></div>
                          <a class="dropdown-item"
                            href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.delete' |
                            translate}}</a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- end card -->

              <div class="card p-2 border mb-2">
                <div class="d-flex align-items-center">
                  <div class="avatar-sm me-3 ms-0">
                    <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                      <i class="ri-image-fill"></i>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <div class="text-start">
                      <h5 class="font-size-14 mb-1">{{'chat.rightpanel.attachedfile.file3.name' | translate}}</h5>
                      <p class="text-muted font-size-13 mb-0">{{'chat.rightpanel.attachedfile.file3.size' | translate}}
                      </p>
                    </div>
                  </div>

                  <div class="ms-4 me-0">
                    <ul class="list-inline mb-0 font-size-18">
                      <li class="list-inline-item">
                        <a href="javascript:void(0);" class="text-muted px-1">
                          <i class="ri-download-2-line"></i>
                        </a>
                      </li>
                      <li class="list-inline-item dropdown" ngbDropdown>
                        <a class="dropdown-toggle text-muted px-1" ngbDropdownToggle href="javascript: void(0);"
                          role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="ri-more-fill"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                          <a class="dropdown-item"
                            href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.action' |
                            translate}}</a>
                          <a class="dropdown-item"
                            href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.another' |
                            translate}}</a>
                          <div class="dropdown-divider"></div>
                          <a class="dropdown-item"
                            href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.delete' |
                            translate}}</a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- end card -->

              <div class="card p-2 border mb-2">
                <div class="d-flex align-items-center">
                  <div class="avatar-sm me-3 ms-0">
                    <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                      <i class="ri-file-text-fill"></i>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <div class="text-start">
                      <h5 class="font-size-14 mb-1">{{'chat.rightpanel.attachedfile.file4.name' | translate}}</h5>
                      <p class="text-muted font-size-13 mb-0">{{'chat.rightpanel.attachedfile.file4.size' | translate}}
                      </p>
                    </div>
                  </div>

                  <div class="ms-4 me-0">
                    <ul class="list-inline mb-0 font-size-18">
                      <li class="list-inline-item">
                        <a href="javascript:void(0);" class="text-muted px-1">
                          <i class="ri-download-2-line"></i>
                        </a>
                      </li>
                      <li class="list-inline-item dropdown" ngbDropdown>
                        <a class="dropdown-toggle text-muted px-1" ngbDropdownToggle href="javascript:void(0);"
                          role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="ri-more-fill"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                          <a class="dropdown-item"
                            href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.action' |
                            translate}}</a>
                          <a class="dropdown-item"
                            href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.another' |
                            translate}}</a>
                          <div class="dropdown-divider"></div>
                          <a class="dropdown-item"
                            href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.delete' |
                            translate}}</a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- end card -->
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
        <!-- End Attached Files card -->
      </div>
      <!-- end profile-user-accordion -->
    </perfect-scrollbar>
    <!-- end user-profile-desc -->

  </div><!--end offcanvas-body-->
</ng-template>



<!-- User Info Sidebar -->
<ng-template #userChatInfo let-offcanvas>
  <div class="offcanvas-header">
    <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
  </div>

  <div class="offcanvas-body profile-offcanvas p-0">
    <div class="text-center p-4 border-bottom">
      <div class="mb-4">
        <img src="{{userProfile}}" class="rounded-circle avatar-lg img-thumbnail" alt="">
      </div>
      <h5 class="font-size-16 mb-1 text-truncate">{{userName | translate}}</h5>
      <p class="text-muted text-truncate mb-1"><i class="ri-record-circle-fill font-size-10 me-1"
          [ngClass]=" {'text-success': userStatus === 'online','text-warning': userStatus === 'away'}"></i>
        {{userStatus | translate}}</p>
    </div>
    <!-- End profile user -->

    <!-- Start user-profile-desc -->
    <perfect-scrollbar class="p-4 user-profile-desc">
      <div class="text-muted">
        <p class="mb-4"> {{'chat.rightpanel.message' | translate}}</p>
      </div>

      <div id="profile-user-accordion" class="custom-accordion">
        <ngb-accordion #acc="ngbAccordion" activeIds="about" [closeOthers]="true">
          <ngb-panel cardClass="card shadow-none border mb-2" id="about">
            <ng-template ngbPanelTitle>
              <h5 class="font-size-14 m-0">
                <i class="ri-user-2-line me-2 align-middle d-inline-block"></i>
                {{'chat.rightpanel.about.text' | translate}}
              </h5>
            </ng-template>
            <ng-template ngbPanelContent>
              <div>
                <p class="text-muted mb-1">{{'chat.rightpanel.about.name.text' | translate}}</p>
                <h5 class="font-size-14">{{userName | translate}}</h5>
              </div>

              <div class="mt-4">
                <p class="text-muted mb-1">{{'chat.rightpanel.about.email.text' | translate}}</p>
                <h5 class="font-size-14">{{'chat.rightpanel.about.email.value' | translate}}</h5>
              </div>

              <div class="mt-4">
                <p class="text-muted mb-1">{{'chat.rightpanel.about.time.text' | translate}}</p>
                <h5 class="font-size-14">{{'chat.rightpanel.about.time.value' | translate}}</h5>
              </div>

              <div class="mt-4">
                <p class="text-muted mb-1">{{'chat.rightpanel.about.location.text' | translate}}</p>
                <h5 class="font-size-14 mb-0">{{'chat.rightpanel.about.location.value' | translate}}</h5>
              </div>
            </ng-template>
          </ngb-panel>
          <ngb-panel cardClass="card shadow-none border mb-2" id="file">
            <ng-template ngbPanelTitle>
              <h5 class="font-size-14 m-0">
                <i class="ri-attachment-line me-2 align-middle d-inline-block"></i>{{'chat.rightpanel.attachedfile.text'
                | translate}}
              </h5>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="card p-2 border mb-2">
                <div class="d-flex align-items-center">
                  <div class="avatar-sm me-3 ms-0">
                    <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                      <i class="ri-file-text-fill"></i>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <div class="text-start">
                      <h5 class="font-size-14 mb-1">{{'chat.rightpanel.attachedfile.file1.name' | translate}}</h5>
                      <p class="text-muted font-size-13 mb-0">{{'chat.rightpanel.attachedfile.file1.size' | translate}}
                      </p>
                    </div>
                  </div>

                  <div class="ms-4 me-0">
                    <ul class="list-inline mb-0 font-size-18">
                      <li class="list-inline-item">
                        <a href="javascript: void(0);" class="text-muted px-1">
                          <i class="ri-download-2-line"></i>
                        </a>
                      </li>
                      <li class="list-inline-item dropdown" ngbDropdown>
                        <a class="dropdown-toggle text-muted px-1" ngbDropdownToggle href="javascript:void(0);"
                          role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="ri-more-fill"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                          <a class="dropdown-item"
                            href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.action' |
                            translate}}</a>
                          <a class="dropdown-item"
                            href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.another' |
                            translate}}</a>
                          <div class="dropdown-divider"></div>
                          <a class="dropdown-item"
                            href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.delete' |
                            translate}}</a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- end card -->

              <div class="card p-2 border mb-2">
                <div class="d-flex align-items-center">
                  <div class="avatar-sm me-3 ms-0">
                    <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                      <i class="ri-image-fill"></i>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <div class="text-start">
                      <h5 class="font-size-14 mb-1">{{'chat.rightpanel.attachedfile.file2.name' | translate}}</h5>
                      <p class="text-muted font-size-13 mb-0">{{'chat.rightpanel.attachedfile.file2.name' | translate}}
                      </p>
                    </div>
                  </div>

                  <div class="ms-4 me-0">
                    <ul class="list-inline mb-0 font-size-18">
                      <li class="list-inline-item">
                        <a href="javascript: void(0);" class="text-muted px-1">
                          <i class="ri-download-2-line"></i>
                        </a>
                      </li>
                      <li class="list-inline-item dropdown" ngbDropdown>
                        <a class="dropdown-toggle text-muted px-1" ngbDropdownToggle href="javascript: void(0);"
                          role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="ri-more-fill"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                          <a class="dropdown-item"
                            href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.action' |
                            translate}}</a>
                          <a class="dropdown-item"
                            href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.another' |
                            translate}}</a>
                          <div class="dropdown-divider"></div>
                          <a class="dropdown-item"
                            href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.delete' |
                            translate}}</a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- end card -->

              <div class="card p-2 border mb-2">
                <div class="d-flex align-items-center">
                  <div class="avatar-sm me-3 ms-0">
                    <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                      <i class="ri-image-fill"></i>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <div class="text-start">
                      <h5 class="font-size-14 mb-1">{{'chat.rightpanel.attachedfile.file3.name' | translate}}</h5>
                      <p class="text-muted font-size-13 mb-0">{{'chat.rightpanel.attachedfile.file3.size' | translate}}
                      </p>
                    </div>
                  </div>

                  <div class="ms-4 me-0">
                    <ul class="list-inline mb-0 font-size-18">
                      <li class="list-inline-item">
                        <a href="javascript:void(0);" class="text-muted px-1">
                          <i class="ri-download-2-line"></i>
                        </a>
                      </li>
                      <li class="list-inline-item dropdown" ngbDropdown>
                        <a class="dropdown-toggle text-muted px-1" ngbDropdownToggle href="javascript: void(0);"
                          role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="ri-more-fill"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                          <a class="dropdown-item"
                            href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.action' |
                            translate}}</a>
                          <a class="dropdown-item"
                            href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.another' |
                            translate}}</a>
                          <div class="dropdown-divider"></div>
                          <a class="dropdown-item"
                            href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.delete' |
                            translate}}</a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- end card -->

              <div class="card p-2 border mb-2">
                <div class="d-flex align-items-center">
                  <div class="avatar-sm me-3 ms-0">
                    <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                      <i class="ri-file-text-fill"></i>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <div class="text-start">
                      <h5 class="font-size-14 mb-1">{{'chat.rightpanel.attachedfile.file4.name' | translate}}</h5>
                      <p class="text-muted font-size-13 mb-0">{{'chat.rightpanel.attachedfile.file4.size' | translate}}
                      </p>
                    </div>
                  </div>

                  <div class="ms-4 me-0">
                    <ul class="list-inline mb-0 font-size-18">
                      <li class="list-inline-item">
                        <a href="javascript:void(0);" class="text-muted px-1">
                          <i class="ri-download-2-line"></i>
                        </a>
                      </li>
                      <li class="list-inline-item dropdown" ngbDropdown>
                        <a class="dropdown-toggle text-muted px-1" ngbDropdownToggle href="javascript:void(0);"
                          role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="ri-more-fill"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                          <a class="dropdown-item"
                            href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.action' |
                            translate}}</a>
                          <a class="dropdown-item"
                            href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.another' |
                            translate}}</a>
                          <div class="dropdown-divider"></div>
                          <a class="dropdown-item"
                            href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.delete' |
                            translate}}</a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- end card -->
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
        <!-- End Attached Files card -->
      </div>
      <!-- end profile-user-accordion -->
    </perfect-scrollbar>
    <!-- end user-profile-desc -->

  </div><!--end offcanvas-body-->
</ng-template>


<ng-template #room let-modal>
<div class="popup-video">
  <div class="list-video">
    <div class="video" *ngFor="let item of remoteVideos" [ngClass]="{active: videoSelected && videoSelected.id === item.id}" (click)="selectVideo(item)">
      <video
        [srcObject]="item.stream"
        muted
        autoplay
        [id]="item.id"
        class="{{item.type}}"
      >
      </video>
    </div>
  </div>
  <video id="video-remote" *ngIf="videoSelected" [srcObject]="videoSelected.stream" muted autoplay></video>

  <div class="waiting" *ngIf="remoteVideos.length < 2">
      <div id="login-logo"></div>
  </div>

  <div class="call-action">

    <div class="horizontal layout center box-call-action">
      
      <div class="flex horizontal layout center center-justified">
        <button class="btn btn-light font-size-16 btn-lg chat-send waves-effect waves-light"  fill="clear" (click)="tabMuteMicro()">
          <i class="ri-mic-line"></i>
          <span class="disabled" *ngIf="muteMicro"></span>
        </button>
      </div>
      <div class="flex horizontal layout center center-justified" (click)="tabMuteVolume()">
        <button class="btn btn-light font-size-16 btn-lg chat-send waves-effect waves-light"  fill="clear">
          <i class="ri-volume-up-line"></i>
          <span class="disabled" *ngIf="muteVolume"></span>
        </button>
      </div>

      <div class="flex horizontal layout center center-justified">
        <button  class="btn btn-light font-size-16 btn-lg chat-send waves-effect waves-light"  fill="clear"
                      (click)="openInvite(true)">
                      <i class="ri-user-add-line"></i>
                    </button>
        </div>
      <div class="flex horizontal layout center center-justified">
        <button class="btn btn-danger font-size-16 btn-lg chat-send waves-effect waves-light"  (click)="closeCall()">
          <i  class="ri-phone-line"></i>
        </button>
      </div>

    </div>



    <div *ngIf="dataService.currentUser.usergroup != 2" class="horizontal layout center box-call-action stat-control">
      <div class="flex horizontal layout center center-justified">
        <button  class="btn btn-light font-size-16 btn-lg chat-send waves-effect waves-light"  fill="clear"
                      (click)="statOff()">
                      Stethoscope off
                    </button>
        </div>
        <div class="flex horizontal layout center center-justified">
        <button  class="btn btn-light font-size-16 btn-lg chat-send waves-effect waves-light"  fill="clear"
                      (click)="statOn()">
                      Stethoscope on
                    </button>
        </div>
      </div>
  </div>
</div>
</ng-template>

<ng-template #videoCall let-modal>

  <div class="modal-header">
    <h5 class="modal-title">{{optionCall.group.member.fullName}} opened a call</h5>
  </div>
  <div class="modal-body">

  
    <div *ngIf="optionCall.group.member.fullName"> 
      <div class="avatar" [ngClass]="type" [ngStyle]="{ 'background-image': 'url(' + optionCall.group.member.avatarFullUrl + ')'}" style="width: 150px;height: 150px;margin-bottom: 30px;" *ngIf="optionCall.group.member.avatarFullUrl" ></div>
      <div *ngIf="!optionCall.group.member.avatarFullUrl" [ngClass]="type" class="avatar horizontal layout center center-justified" style="width: 150px;height: 150px;margin-bottom: 30px;" [ngStyle]="{ 'background-color': optionCall.group.member.bgColor }">{{ optionCall.group.member.fullName ? optionCall.group.member.fullName[0] : "no-name"}}</div>
    </div>
    <div class="call-action">
      <div class="horizontal layout center box-call-action">
  
        <div class="flex horizontal layout center center-justified">

          <button type="submit"
          class="btn btn-danger font-size-16 btn-lg chat-send waves-effect waves-light" (click)="closeCall()">
          Leave
        </button>
        </div>
        <div class="flex horizontal layout center center-justified">

        <button type="submit"
          class="btn btn-success font-size-16 btn-lg chat-send waves-effect waves-light" (click)="joinCall()">
          Join
        </button>
         
        </div>      
      </div>
    </div>
</div>
</ng-template>

<!-- Center Modal -->
<ng-template #centerDataModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Forward to...</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="p-2 card-body">
      <div data-simplebar="init" style="max-height: 200px;">
        <div class="simplebar-wrapper" style="margin: 0px;">
          <div class="simplebar-height-auto-observer-wrapper">
            <div class="simplebar-height-auto-observer"></div>
          </div>
          <div class="simplebar-mask">
            <div class="simplebar-offset" style="right: 0px; bottom: 0px;">
              <div class="simplebar-content-wrapper" tabindex="0" role="region" aria-label="scrollable content"
                style="height: auto; overflow: hidden scroll;">
                <div class="simplebar-content" style="padding: 0px;">
                  <div>
                    <div class="p-3 font-weight-bold text-primary">A</div>
                    <ul class="list-unstyled contact-list">
                      <li>
                        <div class="form-check">
                          <input id="memberCheck1" type="checkbox" class="form-check-input form-check-input"
                            value="Albert Rodarte">
                          <label for="memberCheck1" class="form-check-label">Albert Rodarte</label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input id="memberCheck2" type="checkbox" class="form-check-input form-check-input"
                            value="Allison Etter">
                          <label for="memberCheck2" class="form-check-label">Allison Etter</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div class="p-3 font-weight-bold text-primary">C</div>
                    <ul class="list-unstyled contact-list">
                      <li>
                        <div class="form-check">
                          <input id="memberCheck3" type="checkbox" class="form-check-input form-check-input"
                            value="Craig Smiley">
                          <label for="memberCheck3" class="form-check-label">Craig Smiley</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div class="p-3 font-weight-bold text-primary">D</div>
                    <ul class="list-unstyled contact-list">
                      <li>
                        <div class="form-check">
                          <input id="memberCheck4" type="checkbox" class="form-check-input form-check-input"
                            value="Daniel Clay">
                          <label for="memberCheck4" class="form-check-label">Daniel Clay</label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input id="memberCheck5" type="checkbox" class="form-check-input form-check-input"
                            value="Doris Brown">
                          <label for="memberCheck5" class="form-check-label">Doris Brown</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div class="p-3 font-weight-bold text-primary">I</div>
                    <ul class="list-unstyled contact-list">
                      <li>
                        <div class="form-check">
                          <input id="memberCheck6" type="checkbox" class="form-check-input form-check-input"
                            value="Iris Wells">
                          <label for="memberCheck6" class="form-check-label">Iris Wells</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div class="p-3 font-weight-bold text-primary">J</div>
                    <ul class="list-unstyled contact-list">
                      <li>
                        <div class="form-check">
                          <input id="memberCheck7" type="checkbox" class="form-check-input form-check-input"
                            value="Juan Flakes">
                          <label for="memberCheck7" class="form-check-label">Juan Flakes</label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input id="memberCheck8" type="checkbox" class="form-check-input form-check-input"
                            value="John Hall">
                          <label for="memberCheck8" class="form-check-label">John Hall</label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input id="memberCheck9" type="checkbox" class="form-check-input form-check-input"
                            value="Joy Southern">
                          <label for="memberCheck9" class="form-check-label">Joy Southern</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div class="p-3 font-weight-bold text-primary">M</div>
                    <ul class="list-unstyled contact-list">
                      <li>
                        <div class="form-check">
                          <input id="memberCheck10" type="checkbox" class="form-check-input form-check-input"
                            value="Mary Farmer">
                          <label for="memberCheck10" class="form-check-label">Mary Farmer</label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input id="memberCheck11" type="checkbox" class="form-check-input form-check-input"
                            value="Mark Messer">
                          <label for="memberCheck11" class="form-check-label">Mark Messer</label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input id="memberCheck12" type="checkbox" class="form-check-input form-check-input"
                            value="Michael Hinton">
                          <label for="memberCheck12" class="form-check-label">Michael Hinton</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div class="p-3 font-weight-bold text-primary">O</div>
                    <ul class="list-unstyled contact-list">
                      <li>
                        <div class="form-check">
                          <input id="memberCheck13" type="checkbox" class="form-check-input form-check-input"
                            value="Ossie Wilson">
                          <label for="memberCheck13" class="form-check-label">Ossie Wilson</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div class="p-3 font-weight-bold text-primary">P</div>
                    <ul class="list-unstyled contact-list">
                      <li>
                        <div class="form-check">
                          <input id="memberCheck14" type="checkbox" class="form-check-input form-check-input"
                            value="Phillis Griffin">
                          <label for="memberCheck14" class="form-check-label">Phillis Griffin</label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input id="memberCheck15" type="checkbox" class="form-check-input form-check-input"
                            value="Paul Haynes">
                          <label for="memberCheck15" class="form-check-label">Paul Haynes</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div class="p-3 font-weight-bold text-primary">R</div>
                    <ul class="list-unstyled contact-list">
                      <li>
                        <div class="form-check">
                          <input id="memberCheck16" type="checkbox" class="form-check-input form-check-input"
                            value="Rocky Jackson">
                          <label for="memberCheck16" class="form-check-label">Rocky Jackson</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div class="p-3 font-weight-bold text-primary">S</div>
                    <ul class="list-unstyled contact-list">
                      <li>
                        <div class="form-check">
                          <input id="memberCheck17" type="checkbox" class="form-check-input form-check-input"
                            value="Sara Muller">
                          <label for="memberCheck17" class="form-check-label">Sara Muller</label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input id="memberCheck18" type="checkbox" class="form-check-input form-check-input"
                            value="Simon Velez">
                          <label for="memberCheck18" class="form-check-label">Simon Velez</label>
                        </div>
                      </li>
                      <li>
                        <div class="form-check">
                          <input id="memberCheck19" type="checkbox" class="form-check-input form-check-input"
                            value="Steve Walker">
                          <label for="memberCheck19" class="form-check-label">Steve Walker</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div class="p-3 font-weight-bold text-primary">H</div>
                    <ul class="list-unstyled contact-list">
                      <li>
                        <div class="form-check">
                          <input id="memberCheck20" type="checkbox" class="form-check-input form-check-input"
                            value="Hanah Mile">
                          <label for="memberCheck20" class="form-check-label">Hanah Mile</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="simplebar-placeholder" style="width: auto; height: 1666px;"></div>
        </div>
        <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
          <div class="simplebar-scrollbar" style="width: 0px; display: none;"></div>
        </div>
        <div class="simplebar-track simplebar-vertical" style="visibility: visible;">
          <div class="simplebar-scrollbar" style="height: 25px; transform: translate3d(0px, 0px, 0px); display: block;">
          </div>
        </div>
      </div>
      <div class="border-0 modal-footer"><button class="btn btn-primary">Forward</button></div>
    </div>
  </div>
</ng-template>

