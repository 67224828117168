const baseUrl = 'https://fuaw.uno'
const rtcUrl = 'https://rtc.fuaw.uno/'
// const baseUrl = 'http://localhost:3000'
// const rtcUrl = 'https://rtc.hybridappmarket.com/'
const openTokKey = '47719651'

export default {
  baseUrl,
  rtcUrl,
  openTokKey,
  api: {
    createUser: `${baseUrl}/api/users/create/`,
    login: `${baseUrl}/api/auth/app/`,
    profile: `${baseUrl}/api/users/profile/`,
    updateCompany: `${baseUrl}/api/company/update/`,
    addEmployee:`${baseUrl}/api/users/addEmployee`,  
    joinGroup:`${baseUrl}/api/group/join`,  
    invite: `${baseUrl}/api/group/addtogroup`,    
    updateRole:`${baseUrl}/api/users/updateRole`,  
    upload: `${baseUrl}/api/upload/disk/`,
    changeGroupStatus: `${baseUrl}/api/group/updateStatus/`,
    updateProfile: `${baseUrl}/api/users/update/`,
    contact: `${baseUrl}/api/users/contact/`,
    createGroup: `${baseUrl}/api/group/create/`,
    getGroups: `${baseUrl}/api/group/list/`,
    getArchivedGroups: `${baseUrl}/api/group/listArchive/`,
    getRelatedFiles: `${baseUrl}/api/users/getRelatedFiles/`,
    downloadFile: `${baseUrl}/api/users/downloadFile/`,
    uploadRelatedDocument: `${baseUrl}/api/users/uploaddocument/`,
    getMediaGroup: `${baseUrl}/api/group/media/`,
    detailGroup: `${baseUrl}/api/group/detail/`,
    updateGroup: `${baseUrl}/api/group/update/`,
    sendACall: `${baseUrl}/api/group/call/`,
    createMessage: `${baseUrl}/api/message/create/`,
    listMessage: `${baseUrl}/api/message/list/`,
    readMessage: `${baseUrl}/api/group/read/`,
    deleteMessage: `${baseUrl}/api/message/`,
    createPost: `${baseUrl}/api/post/create/`,
    getPosts: `${baseUrl}/api/post/list/`,
    deletePost: `${baseUrl}/api/post/`,
    createReaction: `${baseUrl}/api/reaction/create/`,
    createComment: `${baseUrl}/api/comment/create/`,
  },
}
